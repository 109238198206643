import React from "react";
import "./style.css";

const Card = (props) => {
  const { cardData } = props;
  console.log(cardData);
  return (
    <>
      {cardData.map((data) => {
        return (
          <div className="search-metadata">
            {data.trait_type} - {data.value}
          </div>
        );
      })}
    </>
  );
};
export default Card;
