import React, { useState, useEffect } from "react";
import "./style.css";
import SearchIcon from "../../assets/images/sear_ccexpress.png";
import PropagateLoader from "react-spinners/PropagateLoader";
import Card from "../card/card";

const Home = () => {
  const hasWindow = typeof window !== "undefined";

  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);
  const [txtSearch, setTxtSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stakeData, setStakeData] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [stakingValue, setStakingValue] = useState("");
  const onHandleChane = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setTxtSearch(e.target.value);
    }
    if (e.target.value.length === 0) {
      setStakeData([]);
      setIsAlert(false);
    }
  };

  const onSearch = () => {
    if (txtSearch) {
      setIsLoading(true);
      setIsAlert(true);
      setStakingValue(txtSearch);
      fetch("https://godjirastaking.0xytocin.online/metadata/" + txtSearch)
        .then((res) => res.json())
        .then((json) => {
          setStakeData(json.attributes);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setStakeData([]);
        });
    }
  };
  return (
    <div
      className="gobjira-search"
      style={{
        height: windowDimensions.height - 1 + "px",
        backgroundSize: "100% " + (windowDimensions.height - 1) + "px",
      }}
    >
      <div className="search-cnt">
        <div className="godjira-Logo">Logo</div>
        <div className="input-cnt">
          <img
            src={SearchIcon}
            alt="searchIcon"
            className="search-icon"
            autocomplete="off"
          />
          <input
            className="search-input"
            type={"text"}
            name="seach"
            value={txtSearch}
            onChange={(e) => onHandleChane(e)}
            placeholder="Enter staking pass number to check it's content"
          />
        </div>
        <div>
          <button className="search-btn" onClick={() => onSearch()}>
            Search
          </button>
        </div>
        {isLoading ? (
          <div className="search-loading">
            <PropagateLoader
              color="rgb(54, 215, 183)"
              loading={true}
              size={20}
            />
          </div>
        ) : txtSearch && stakeData.length > 0 ? (
          <div className="searched-data">
            <div className="search-result">
              Contents of Godjira Staking Pass #{stakingValue}
            </div>
            <div className="search-atrCnt">
              <Card cardData={stakeData} />
            </div>
          </div>
        ) : isAlert && txtSearch && stakeData.length === 0 ? (
          <div className="search-alert">Not valid </div>
        ) : (
          ""
        )}
      </div>
      <div className="staking-url">
        <a
          href="https://opensea.io/collection/godjira-staking-pass"
          target="_blank"
          className="collection-link"
        >
          View collection on OpenSea
        </a>
      </div>
    </div>
  );
};
export default Home;
